import React from 'react'
import PropTypes from 'prop-types'
import ScriptRender from '@components/social-embed'

const Banner = (props) => {
  const { socialContent } = props
  return <ScriptRender socialContent={socialContent} />
}

Banner.propTypes = {
  socialContent: PropTypes.string,
}

export default Banner
